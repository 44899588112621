import React from "react";
import Discord from "../assets/discord.png";
import Facebook from "../assets/fb.png";
import Email from "../assets/email.png";

const Contact = () => {
  return (
    <div className="w-full py-16 px-4">
      <div className="max-w-[1240px] mx-auto grid md:grid-row-2">
        <div className="flex flex-col justify-center">
          <p className="text-[#1e76ba] font-bold text-2xl">KAPCSOLAT</p>
        </div>
        <div className="max-w-[450px] mx-auto grid md:grid-cols-3 px-4">
          <a
            className="px-4"
            href="https://discord.com/invite/feregtv"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="max-w-[450] mx-auto my-4 hover:scale-105 duration-300"
              src={Discord}
              alt="/"
            />
          </a>
          <a
            className="px-4"
            href="https://www.facebook.com/feregtv"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="w-[450] mx-auto my-4 hover:scale-105 duration-300"
              src={Facebook}
              alt="/"
            />
          </a>
          <a
            className="px-4"
            href="mailto:info@fereg.tv"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="w-[450] mx-auto my-4 hover:scale-105 duration-300"
              src={Email}
              alt="/"
            />
          </a>
        </div>
        <p className="text-white">info@fereg.tv</p>
      </div>
    </div>
  );
};

export default Contact;
