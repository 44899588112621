import React from "react";
import { useRef, useEffect } from "react";
import { Link } from "react-scroll";
import Landing from "./components/Landing";
import Broadcasts from "./components/Broadcast";
import Casino from "./components/Casino";
import Contact from "./components/Contact";

function App() {
  const ref = useRef(null);

  const handleCasino = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (window.location.href.includes("kaszi")) {
      handleCasino();
    }
  }, []);

  return (
    <div>
      <div id="stars"></div>
      <div id="stars2"></div>
      <div id="stars3"></div>
      <div id="stars4"></div>
      <div className="App">
        <header className="nav">
          <nav className="nav__container__actions">
            <ul>
              <li>
                <Link activeClass="active" smooth spy to="landing">
                  FŐOLDAL
                </Link>
              </li>
              <li>
                <Link activeClass="active" smooth spy to="broadcasts">
                  ADÁSOK
                </Link>
              </li>
              <li>
                <Link activeClass="active" smooth spy to="casinos">
                  KASZINÓK
                </Link>
              </li>
              <li>
                <Link activeClass="active" smooth spy to="contact">
                  KAPCSOLAT
                </Link>
              </li>
            </ul>
          </nav>
        </header>
        <section id="landing">
          <Landing />
        </section>
        <section id="broadcasts">
          <Broadcasts />
        </section>
        <section ref={ref} id="casinos">
          <Casino />
        </section>
        <section id="contact">
          <Contact />
        </section>
      </div>
    </div>
  );
}

export default App;
