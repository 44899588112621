import React from "react";
import Twitch from "../assets/twitch.png";

const Broadcasts = () => {
  return (
    <div className="w-full py-16 px-4">
      <div className="max-w-[1240px] mx-auto grid md:grid-cols-2">
        <a href="https://twitch.com/feregtv" target="_blank" rel="noreferrer">
          <img
            className="w-[300px] mx-auto my-4 hover:scale-105 duration-300"
            src={Twitch}
            alt="/"
          />
        </a>
        <div className="flex flex-col justify-center">
          <p className="text-[#1e76ba] font-bold text-2xl">
            FÉREG TELEVíZIÓ ADÁSAI
          </p>
          <p className="text-white text-2xl font-semibold">
            A Féreg TV hétfőtől csütörtökig valamint vasárnap 15:00-tól közvetít
            élőben a Twitchen.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Broadcasts;
