import React, { useEffect, useState } from "react";
import CasinoCard from "./CasinoCard/CasinoCard";
import Sol from "../assets/sol.png";
import Izzi from "../assets/izzi.png";
import Jet from "../assets/jet.png";
import Ggbet from "../assets/ggbet.png";
import Winscore from "../assets/winscore.png";
import Fairspin from "../assets/fairspin.png";
import "./../index.css";

const Casino = () => {
  const url = window.location.href;
  const pathname = window.location.pathname;
  const protocol = window.location.protocol;
  const hostname = window.location.hostname;

  const [glowFair, setGlowFair] = useState(false);
  const [glowWinscore, setGlowWinscore] = useState(false);
  const [glowSol, setGlowSol] = useState(false);
  const [glowGgbet, setGlowGgbet] = useState(false);
  const [glowIzzi, setGlowIzzi] = useState(false);
  const [glowJet, setGlowJet] = useState(false);

  useEffect(() => {
    if (pathname.length > 7) {
      if (pathname.includes("fairspin")) setGlowFair(true);
      if (pathname.includes("winscore")) setGlowWinscore(true);
      if (pathname.includes("sol")) setGlowSol(true);
      if (pathname.includes("ggbet")) setGlowGgbet(true);
      if (pathname.includes("izzi")) setGlowIzzi(true);
      if (pathname.includes("jet")) setGlowJet(true);
    }
  }, []);

  return (
    <div className="w-full py-16 px-4">
      <div className="max-w-[1240px] mx-auto grid md:grid-cols-3 gap-8">
        <CasinoCard
          title="FAIRSPIN"
          descripton="200 TFS token, 450%-os üdvözlőcsomag és akár 140 ingyenes pörgetés a linken keresztül regisztrálóknak. Ha nem működik a link próbáld inkognitó ablakban."
          buttonText="REGISZTRÁCIÓ"
          refUrl="https://fairspin-he.click/64957/1?l=2"
          url={Fairspin}
          glow={glowFair}
        />
        <CasinoCard
          title="WINSCORE"
          descripton="Welcome package: akár 300% befizetési bónusszal 1k euróig VAGY
              100%-200% befizetési bónusz és 50 free spin a BELESFS kóddal."
          buttonText="REGISZTRÁCIÓ"
          refUrl="https://go.winscorepartners.com/visit/?bta=35523&brand=winscore"
          url={Winscore}
          glow={glowWinscore}
        />

        <CasinoCard
          title="SOL"
          descripton="100% befizetési bónusz és akár 500 free spin! A BELES kóddal 50 free spin KYC után a Doors Of Sol (BGaming) játékban."
          buttonText="REGISZTRÁCIÓ"
          refUrl="https://sol-diamew02.com/c06b013d6"
          url={Sol}
          glow={glowSol}
        />

        <CasinoCard
          title="GG.BET"
          descripton="Akár 600k HUF befizetési bónusz és 500 free spin különböző játékokon. Sport és esport fogadásra ajánlott. Bankkártyát elfogad."
          buttonText="REGISZTRÁCIÓ"
          refUrl="https://mercuryeasy.com/gg/gb/?param=fereg&lp=00&lang=00&go=sitereg&deeplink=/promotion/welcome-bonus-pack"
          url={Ggbet}
          glow={glowGgbet}
        />

        <CasinoCard
          title="IZZI"
          descripton="100% befizetési bónusz és akár 500 free spin! A BELES kóddal 50 free spin KYC után az Avalon: The Lost Kingdom (BGaming) játékban."
          buttonText="REGISZTRÁCIÓ"
          refUrl="https://izzi-irrs01.com/cf9179771"
          url={Izzi}
          glow={glowIzzi}
        />

        <CasinoCard
          title="JET"
          descripton="100% befizetési bónusz és akár 500 free spin! A BELES kóddal 50 free spin KYC után a Candy Monsta (BGaming) játékban."
          buttonText="REGISZTRÁCIÓ"
          refUrl="https://jtfr-itsokarsn.com/cce745560"
          url={Jet}
          glow={glowJet}
        />
      </div>
    </div>
  );
};

export default Casino;
