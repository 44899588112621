import React from "react";
import LogoGif from "../assets/logo_1.gif";
import { ReactTyped } from "react-typed";

const Landing = () => {
  return (
    <div className="text-white">
      <div className="max-w-[800px] mt-[-96px] w-full h-screen mx-auto text-center flex flex-col justify-center">
        <img
          className="max-w-[450] mx-auto my-4 hover:scale-105 duration-300"
          src={LogoGif}
          alt="/"
        />
        <p className="text-[#1e76ba] font-bold p-2">
          A TITKOS INTERNETES LÓFEJBARLANG
        </p>
        <h1 className="md:text-7xl sm:text-6xl text-4xl font-bold md:py-6">
          Féreg Televízió.
        </h1>
        <div className="flex justify-center items-center">
          <ReactTyped
            className="md:text-5xl sm:text-4xl text-xl font-bold md:pl-4 pl-2"
            strings={["FEÖ", "MEÖ", "PEÖ"]}
            typeSpeed={90}
            backSpeed={110}
            loop
          />
        </div>
      </div>
    </div>
  );
};

export default Landing;
