import React from "react";
import "./neon.css";

const CasinoCard = (props) => {
  return (
    <div
      className={
        (props.glow ? "block glow " : "block ") +
        "bg-[#1e76ba] w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300"
      }
    >
      <img
        className="w-20 mx-auto mt-[-3rem] rounded-md border border-[#1e76ba]"
        src={props.url}
        alt=""
      />
      <h2 className="text-2xl font-bold text-center py-8"> {props.title} </h2>
      <div className="text-center font-medium">
        <p>{props.descripton}</p>
      </div>
      <a href={props.refUrl} target="_blank" rel="noopener noreferrer">
        <button className="bg-black text-[#1e76ba]  rounded-md font-medium w-[200px] mx-auto my-6 px-6 py-3">
          {props.buttonText}
        </button>
      </a>
    </div>
  );
};

export default CasinoCard;
